/*
 * APIs are powered by PowerLego
 * Website: https://www.powerlego.com/
 */

import { API_URL, ACC_KEY, IS_DEV, CS_API_URL, checkWhitelistUrl, paidCustomerUrl, adminUrls, AdminApiUrl, accessTokenForDeals, dealPipeline, contactSubmissionURL, bearerToken } from '../config';
import getCookieData from '../utils/getCookieData';
import getIpAddress from '../utils/getIpAddress';


const BearerObject = {Authorization: `Bearer ${bearerToken}`}

/*
 * Address Validator
 * Use cases:        https://www.powerlego.com/cases/address_validator
 * Postman examples: https://documenter.getpostman.com/view/4831254/SzKYPH3c#ca1bdf1f-2318-4fed-aaeb-88708d7c34eb
 */
export const fetchAddress = async (val) => {
    const string = val.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
    
    const formdata = new FormData();
    formdata.append("term", string);
    
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/address_validator`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchTxUtility = async (zipcode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${API_URL}v1/get_utility`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchUtility = async (zipcode, promoCode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("zipcode", zipcode);
    if (promoCode) {
        formdata.append("promo_code", promoCode);
    }

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    };

    const url = `${CS_API_URL}utility`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const fetchProducts = async (utilityCode, zipcode, promoCode) => {
    try {
        // const myHeaders = new Headers()
        // myHeaders.append('Authorization', `Bearer ${ACC_KEY}`)

        const formdata = new FormData();
        formdata.append("zipcode", zipcode);
        formdata.append("utility_code", utilityCode);
        if (promoCode) {
            formdata.append("promo_code", promoCode);
        }

        const requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        let url = `${CS_API_URL}enrollment/rate`

        const response = await fetch(url, requestOptions)
        return response.json()
    }
    catch(err) {
        console.log(err)
    }
}

export const fetchRateCompare = async (tduCode, term) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `https://bb0xg7o51l.execute-api.us-east-2.amazonaws.com/beta/CleanskyCompare`;

    const formdata = new FormData();
    formdata.append("utility_code", tduCode);
    formdata.append("term", term);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchValidatePromoCode = async (promoCode) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/promo_code_validate`;

    const formdata = new FormData();
    formdata.append("promo_code", promoCode);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchValidateEmail = async (email) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${API_URL}v2/gc/email_validate`;

    const formdata = new FormData();
    formdata.append("email", email);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const fetchLocation = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders
    };

    const url = `${API_URL}v2/tools/ip2location`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const postToHubSpot = async (
    enrollment_id, marketing_opt_in, opt_in_sms, paperless, email, phone, firstName, lastName, promocode, state, abandoned_status, magic_link, pageName
) => {
    const hutk = getCookieData("hubspotutk");
    const pageUri = window.location.href;
    const ipAddress = await getIpAddress();
    const newPhone = `+1${String(phone)}`;
    try{
        const response = await fetch(
            contactSubmissionURL,
            {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json'
                },
                body: JSON.stringify({
                    fields: [
                        {
                            "objectTypeId": "0-1",
                            "name": "marketing_opt_in",
                            "value": marketing_opt_in
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "opt_in_sms",
                            "value": opt_in_sms
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "paperless",
                            "value": paperless
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "email",
                            "value": email
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "phone",
                            "value": newPhone
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "firstname",
                            "value": firstName
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "lastname",
                            "value": lastName
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "promocode",
                            "value": promocode
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "state",
                            "value": state
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "Abandoned Status",
                            "value": abandoned_status
                        },
                        {
                            "objectTypeId": "0-1",
                            "name": "Magic Link",
                            "value": magic_link
                        }
                    ],
                    "context": {
                        "hutk": hutk,
                        "pageUri": pageUri,
                        "pageName": pageName,
                        "ipAddress": ipAddress
                    }
                })
            }
        )
        return response.json()
    }catch(err){
        hubspotErrorLogs(enrollment_id, "Create_Contact", JSON.stringify({
            fields: [
                {
                    "objectTypeId": "0-1",
                    "name": "marketing_opt_in",
                    "value": marketing_opt_in
                },
                {
                    "objectTypeId": "0-1",
                    "name": "opt_in_sms",
                    "value": opt_in_sms
                },
                {
                    "objectTypeId": "0-1",
                    "name": "paperless",
                    "value": paperless
                },
                {
                    "objectTypeId": "0-1",
                    "name": "email",
                    "value": email
                },
                {
                    "objectTypeId": "0-1",
                    "name": "phone",
                    "value": newPhone
                },
                {
                    "objectTypeId": "0-1",
                    "name": "firstname",
                    "value": firstName
                },
                {
                    "objectTypeId": "0-1",
                    "name": "lastname",
                    "value": lastName
                },
                {
                    "objectTypeId": "0-1",
                    "name": "promocode",
                    "value": promocode
                },
                {
                    "objectTypeId": "0-1",
                    "name": "state",
                    "value": state
                },
                {
                    "objectTypeId": "0-1",
                    "name": "Abandoned Status",
                    "value": abandoned_status
                },
                {
                    "objectTypeId": "0-1",
                    "name": "Magic Link",
                    "value": magic_link
                }
            ],
            "context": {
                "hutk": hutk,
                "pageUri": pageUri,
                "pageName": pageName,
                "ipAddress": ipAddress
            }
        }), err)
    }
}

export const hubspotErrorLogs = async (id, purpose, request_data, response_data ) => {
    const myHeaders = new Headers();

    let url = `${CS_API_URL}hubspot/HubspotFrontEndLog`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("purpose", purpose);
    formdata.append("request_data", request_data);
    formdata.append("response_data", response_data);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });
} 

export const fetchIsWhitelisted = async (id) => {

    const requestOptions = {
        method: 'GET',
        headers:{...BearerObject}
    };

    const url = `${checkWhitelistUrl}?id=${id}`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const paidCustomer = async (id, method) => {

    const requestOptions = {
        method: 'GET',
        headers:{...BearerObject}
    };

    const url = `${paidCustomerUrl}?id=${id}&method=${method}`

    const response = await fetch(url, requestOptions)
    return response.json()
}


export const getAgentScript = async (params) => {

    const requestOptions = {
        method: 'GET',
        headers:{...BearerObject}
    };
    let url;
    // const url = `${AdminApiUrl}agent/getAgentScript?script_id=${params?.script_id}&state=${params?.state}&utility_code=${params?.id_utility}&language=${params?.language}&commodity=${params?.commodity}&enrollment_id=${params?.enrollment_id}`
    if(params?.enrollment_id){
        url = `${AdminApiUrl}agent/getAgentScript?script_id=${params?.script_id}&state=${params?.state}&utility_code=60&language=${params?.language}&commodity=${params?.commodity}&enrollment_id=${params?.enrollment_id}`
    }else{
        url = `${AdminApiUrl}agent/getAgentScript?script_id=${params?.script_id}&state=${params?.state}&utility_code=60&language=${params?.language}&commodity=${params?.commodity}`
    }

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const uiLogging = async (id, sign, msg) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}enrollment/ui_custom_message`;

    const formdata = new FormData();
    formdata.append("message", `(${id}) ${msg}`);
    formdata.append("status", "800");
    formdata.append("sign", sign);
    formdata.append("enrollment_id", id);

    const response = await fetch(url, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    });

    return response.json()
}

export const isDepositWaived = async (id, sign) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}enrollment/getDepositWaiver`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const setORSession = async (id, session_id) =>{
    const requestOptions = {
        method: 'GET',
        headers:{...BearerObject}
    };
    const url = `${adminUrls}user/setORSession?enrollment_id=${id}&session_id=${session_id}`
    await fetch(url, requestOptions)
}

//HUBSPOT DEALS RELATED APIs
export const getContactIdFromEmail = async(email, enrollment_id) =>{
    try{
        const responseBody = {
            "filterGroups":[
              {
                "filters":[
                  {
                    "propertyName": "email",
                    "operator": "EQ",
                    "value": email
                  }
                ]
              }
            ],
            "enrollment_id": enrollment_id
        }
        const response = await fetch(`${CS_API_URL}hubspot/HubSpotFetchContact`,
            {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${accessTokenForDeals}`
                },
                body: JSON.stringify(responseBody)
            }
        )
        let finalRes = await response.json()
        const finalResponse = JSON.parse(finalRes.response)
        return finalResponse.results[0].id;
    }catch(err){
        console.log("Caught while fetching contactID from email", err);
        return null;
    }
}

async function retryGetContactId(email, enrollment_id, maxRetries = 4, retryInterval = 6000) {
    let contactId = null;
    let retries = 0;
  
    while (contactId === null && retries < maxRetries) {
        contactId = await getContactIdFromEmail(email, enrollment_id);
    
        if (contactId === null) {
            retries++;
            if (retries < maxRetries) {
            // Wait for the specified interval before the next retry
            await new Promise(resolve => setTimeout(resolve, retryInterval));
            }
        }
    }
  
    return contactId;
}

export const createDealHubspot = async(enrollmentId, promoCode, reviewData, selectedPlans, dealStage) =>{
    try{
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();

        //API call to get contactId from email - required for assoiation with deals
        const contactId = await retryGetContactId(reviewData.email, enrollmentId)

        const requestBody = {
            "properties":{ 
                "promocode" : promoCode,
                "contract_start_date" : reviewData.requested_date,
                "dealname" : `${enrollmentId}_${reviewData.first_name}_${reviewData.last_name}_${year}${month}${day}`,
                "dealstage" : dealStage,
                "pipeline": dealPipeline,
                // "plan_term" : `${selectedPlans.contract_term || 0} Months`,
                "plan_name___multi_select" : String(selectedPlans.plan_name),
                "plan_rate" : String(selectedPlans.price2000),
                "orderid": enrollmentId
            },
            "associations": [
            {
                "to": {
                    "id": contactId
                },
                "types": [
                    {
                        "associationCategory": "HUBSPOT_DEFINED",
                        "associationTypeId": 3
                    }
                ]
            }],
            "enrollment_id": enrollmentId
        }

        const response = await fetch(`${CS_API_URL}hubspot/createDeal`,
            {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${accessTokenForDeals}`
                },
                body: JSON.stringify(requestBody)
            }
        )
    }catch(err){
        console.log("Caught while creating a deal", err);
    }
}

export const getDealIdFromOrderId = async(enrollmentId) =>{
    try{
        const responseBody = {
            "filterGroups":[
              {
                "filters":[
                  {
                    "propertyName": "orderid",
                    "operator": "EQ",
                    "value": enrollmentId
                  }
                ]
              }
            ],
            "enrollment_id": enrollmentId
        }
        const response = await fetch(`${CS_API_URL}hubspot/getDealsByOrderId`,
            {
                method: 'POST',
                headers: {
                    'content-Type': 'application/json',
                    'Authorization': `Bearer ${accessTokenForDeals}`
                },
                body: JSON.stringify(responseBody)
            }
        )
        let finalRes = await response.json()
        const finalResponse = JSON.parse(finalRes.response)
        return finalResponse.results[0].id;
    }catch(err){
        console.log("Caught while fetching DealID from orderid", err);
        return null;
    }
}

export const updateDealHubspot = async(enrollmentId, dealStage) =>{
    try{

        //API call to get dealId from orderId - required for assoiation with deals
        const dealId = await getDealIdFromOrderId(enrollmentId)

        const requestBody = new FormData()
        requestBody.append("dealstage", parseInt(dealStage));
        requestBody.append("dealid", parseInt(dealId));
        requestBody.append("enrollment_id", parseInt(enrollmentId));

        const response = await fetch(`${CS_API_URL}hubspot/UpdateDeal`,
            {
                method: 'POST',
                body: requestBody
            }
        )
    }catch(err){
        console.log("Caught while updating a deal", err);
    }
}

//AGENT RELATED APIS
export const getIsAgentValid = async (params) => {

    const requestOptions = {
        method: 'GET',
        headers:{...BearerObject}
    };

    const url = `${AdminApiUrl}agent/checkAgent?agent_id=${params?.id}`

    const response = await fetch(url, requestOptions)
    return response.json()
}

export const getSwitchHoldDetails = async (esiid) => {

    const requestOptions = {
        method: 'POST',
    };

    const url = `${CS_API_URL}enrollment/getSwitchHoldDetails`

    const formdata = new FormData();
    formdata.append("esiid", esiid);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const getSwitchVelocityDetails = async (esiid) => {

    const requestOptions = {
        method: 'POST',
    };

    const url = `${CS_API_URL}enrollment/getSwitchVelocityDetails`

    const formdata = new FormData();
    formdata.append("esiid", esiid);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const sendMagicLinkEmail = async (id, email) => {

    const url = `${CS_API_URL}hubspot/getProperty`

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("email_id", email);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const sendCustomMagicLink = async (id, sign, email, language, magic_link_screen, purpose) => {

    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;
    const magic_link = `${baseUrl}/${magic_link_screen}?id=${id}&sign=${sign}`;
    const url = `${CS_API_URL}hubspot/HubspotMail`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("email_id", email);
    formdata.append("sign", sign);
    formdata.append("magic_link", magic_link);
    formdata.append("language", language);
    formdata.append("purpose", purpose);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const checkExistingCustomer = async (id, sign) => {

    const url = `${CS_API_URL}enrollment/ExistingCustomer`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const getCreditCheckData = async (id, sign) => {

    const url = `${CS_API_URL}enrollment/getEnrollmentData`;
    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const updateCreditCheckData = async (id, sign, ssn ,dob) => {

    const url = `${CS_API_URL}enrollment/saveEnrollmentData`;
    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    formdata.append("ssn", ssn);
    formdata.append("date_of_birth", dob);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const documentUploader = async ( fileList, id, sign ) =>{
    const url = `${CS_API_URL}document/saveEnrollmentDocument`;
 
    const formdata = new FormData();
    fileList?.map(file => {
        formdata.append("filesToUpload[]", file.originFileObj);
    })
 
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
 
    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });
 
    return response.json()
}

export const fraudDocumentUploader = async (fileList, id, sign,document_type) => {
    const url = `${CS_API_URL}document/saveDocument`;

    const formdata = new FormData();

    // Convert each file to Base64 and append it to FormData
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj); // Convert file to Base64
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    for (const file of fileList) {
        try {
            const base64File = await convertFileToBase64(file); // Wait for Base64 conversion
            console.log('Base64 of file ==>', base64File); // Optional log for debugging
            formdata.append("filesToUpload[]", base64File.split(',')[1]); // Append Base64 string to FormData
            formdata.append("filesName[]", file.name); // Append Base64 string to FormData            
        } catch (error) {
            console.error('Error converting file to Base64', error);
        }
    }

    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    formdata.append("document_type", document_type);

    // Send FormData with Base64 encoded files
    const response = await fetch(url, {
        method: 'POST',
        body: formdata,
    });

    return response.json();
};


export const updateStatus = async (id, sign, status, sub_status, fraud_flag) => {

    try{const url = `${CS_API_URL}enrollment/saveStatus`

    const formdata = new FormData();

    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    if(status){
        formdata.append("status", status);
    }
    if(sub_status){
        formdata.append("sub_status", sub_status);
    }
    if(fraud_flag){
        formdata.append("fraud_flag", fraud_flag);
    }

        const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()}catch(err){
        return null;
    }
}

 
export const fetchUploadedDocuments = async (id, sign) => {
    const url = `${CS_API_URL}document/getDocument`;
 
    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });
 
    return response.json()
}

export const checkDebtPayStatus = async (id, sign) => {
    const url = `${CS_API_URL}enrollment/CheckDebtPayment`;
 
    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });
 
    return response.json()
}

export const  getConfigById = async(id) => {
    const url = `${AdminApiUrl}user/configDetails?configId=${id}`;
    const response = await fetch(url, {
        method: 'GET',
        headers:{...BearerObject}
    });
    return response.json()
}

export const fetchUSNCompareData = async (zipcode) => {
    
    const formData = new URLSearchParams();
    formData.append('zipcode', zipcode);
    
    const url = `${API_URL}v2/utility/get`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${ACC_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData
    });

    const _res = await response.json();
    return _res;
}

export const fetchUSNCompareDataNew = async (zipcode, utility_id) => {
    
    const url = `${CS_API_URL}enrollment/Ptc`;
    const formdata = new FormData();
    formdata.append("zipcode", zipcode);
    formdata.append("utility_id", utility_id);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const saveCookieData = async (id,sign, cookie_preference,cookie_date) => {

    const requestOptions = {
        method: 'POST',
    };

    const url = `${CS_API_URL}enrollment/saveEnrollmentData`

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    formdata.append("cookie_preference",cookie_preference);
    formdata.append("cookie_date",cookie_date);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}
export const getDepositConfig = async (id, sign) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}enrollment/getConfig`;

    const formdata = new FormData();
    formdata.append("configId", "split_payment");

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const getReceiptData = async (id, sign) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}enrollment/PaymentReciept`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const getPaymentURL = async (id, sign) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}hubspot/SingleMagicLink`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const saveProspectPay = async (id, sign, prospect_pay) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${CS_API_URL}enrollment/saveStatus`;

    const formdata = new FormData();
    formdata.append("enrollment_id", id);
    formdata.append("sign", sign);
    formdata.append("prospect_pay", prospect_pay);

    const response = await fetch(url, {
        method: 'POST',
        body: formdata
    });

    return response.json()
}

export const getCustomerDataForRedirection = async (id, sign) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    let url = `${AdminApiUrl}user/magicLinkRedirect?id=${id}`;

    const response = await fetch(url, {
        method: 'GET'
    });

    return response.json()
}