import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    EnrollmentStepWrapper,
    EnrollmentStepFooter,
    EnrollmentFormRow,
    EnrollmentFormCol
} from '../styles/Enrollment.styled'
import { Form, Spinner } from 'react-bootstrap'
import { Button } from '../styles/Button.styled'
import { RadioBox } from './RadioBox'
import { FormError } from './FormError'
import { fetchBlackoutDate, saveEnrollment } from '../../api/enrollment'
import moment, { Moment } from 'moment'
import Datetime from 'react-datetime'
import { ValidateAddress } from '../../validate_address'
import { EnrollmentContext } from '../../enrollment'
import { dunsUtilityList } from '../../config'
import { fetchTxUtility, getAgentScript, getSwitchHoldDetails, saveCookieData } from '../../api/api'
import 'moment/locale/es'
import jsonToArrConverter from '../../utils/jsonToArrConverter'
import SwitchHoldStatus from './AgentWebPortal/SwitchHoldStatus'
import isAdmin from '../../utils/isAdmin'
import { CheckboxItem } from '../checkbox-item'
import getCookieData from '../../utils/getCookieData'

interface Props {
    show: boolean;
    handleContinue: Function;
    selectedUtlity: any;
    planIsLoaded: boolean;
    isShowPlanModal: boolean;
    handleOpenPlanModal: Function;
    setScriptData:Function;
    setEnrollmentTokens: Function;
    manualAddressFields: boolean;
    setManualAddressFields: Function;
    selectedPlans: any;
}

interface ServiceTypeOption {
    label: string
    value: string
    desc: string
}

export const EnrollmentStepServiceTx: React.FC<Props> = ({
    show,
    handleContinue,
    selectedUtlity,
    planIsLoaded,
    isShowPlanModal,
    handleOpenPlanModal,
    setScriptData,
    setEnrollmentTokens,
    manualAddressFields,
    setManualAddressFields
}) => {
    const {
        serviceAddress, handleSetErrorModalContent, selectedPlans, changeUtilityList,enrollmentTokens
    } = useContext<any>(EnrollmentContext);
    const { t, i18n } = useTranslation('enrollment');
    const [ serviceType, setServiceType ] = useState<ServiceTypeOption | undefined>(undefined);
    const [ startDate, setStartDate ] = useState<undefined | Moment>(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ errorFields, setErrorFields ] = useState<any>({});
    const [ loadingUtility, setLoadingUtility ] = useState(false);
    const [ holidays, setHolidays ] = useState<any>(undefined);
    const [isAgent,setAgent] = useState(false);
    const [switchStatus,setSwitchStatus] = useState(true)

    const [ switchingType, setSwitchingType ] = useState<any>("StandardSwitch");
    const [ showDatepicker, setShowDatePicker ] = useState<boolean>(false);

    const serviceTypeOptions = [
        {
            id: 'service-moving',
            label: t('Moving/NewService'),
            value: '1',
            desc: t('I am moving and establishing service under my name at this address.'),
        },
        {
            id: 'service-switching-later',
            label: t('Switching'),
            value: '2',
            desc: t('I currently have service in my name. I am not moving. I want to switch to CleanSky Energy. <b>NOTE: If there is no service to the meter at this location, please select the Moving/New Service option to avoid a \"lights-out\" situation.</b>'),
        }
    ]

    const getScriptForAgent = async()=>{
        try{
            const res = await getAgentScript({
                script_id:selectedPlans.length>0?"1":"9",
                language:i18n.language === 'es' ? 'spanish' : 'english',
                state:"TX",
                enrollment_id: enrollmentTokens[0]?.enrollment_id,
                commodity: "Electric",
                utility_code: selectedPlans[0]?.id_utility,
            })
            setScriptData({
                //@ts-ignore
                script: res.length>0 ? res[0]?.script_content:"",
                scriptHead: res.length>0 ? res[0]?.screen_name:"",
                dynamicData:{}
            })
        }catch(e){
            console.log(e)
        }
    }

   
    useEffect(() => {
        if (show) {
            window.scrollTo(0, 0);
            (async()=>{
                let _isAdmin = await isAdmin();
                setAgent(_isAdmin)
                if(_isAdmin){
                    getScriptForAgent();
                }
            })();
        }
    }, [show,isShowPlanModal])

    useEffect(() => {
        window.scrollTo(0, 0);
        setHolidays(undefined);
    }, [])

    useEffect(() => {
        if (show && selectedUtlity && selectedUtlity.Electric) {
            fetchBlackoutDate(selectedUtlity.Electric, selectedPlans[0]?.available_from_date, selectedPlans[0]?.available_until_date).then(res => {
                if (res && res.response) {
                    setHolidays(res.response);
                }
            })
        }

        return(()=>{
            setStartDate(undefined)
            setServiceType(undefined)
        })
    }, [selectedUtlity, selectedPlans[0]?.available_from_date])


    function convertObjectToArray(obj: any) {
        if (typeof obj === 'object' && obj !== null) {
            return Object.values(obj);
        } else {
            return obj;
        }
    }

    const getAutoStartDate = () =>{
        const today = new Date().toISOString().split('T')[0];
        let currentDate = new Date(today);
        while (true) {
            currentDate.setDate(currentDate.getDate() + 1);
            const formattedDate = currentDate.toISOString().split('T')[0];
            if (!convertObjectToArray(holidays?.blackout_dates)?.includes(formattedDate)) {
                return formattedDate;
            }
        }
    }

    const validate = () => {
        let isValid = true
        let newErrorFields: any = {}
        if (!serviceType) {
            newErrorFields['serviceType'] = t('This field is required')
            isValid = false
        }
        if (serviceType?.value !== '-1') {
            if (
                !startDate ||
                !(
                    moment(startDate, 'MM/DD/YYYY', true).isValid() ||
                    moment(startDate, 'M/DD/YYYY', true).isValid() ||
                    moment(startDate, 'MM/D/YYYY', true).isValid() ||
                    moment(startDate, 'M/D/YYYY', true).isValid()
                )
            ) {
                newErrorFields['startDate'] = t('Invalid Date')
                isValid = false
            }
        
            if (holidays && startDate) {
                if (startDate.isBefore(moment().format('YYYY-MM-DD')) || startDate.isAfter(moment(holidays.end_date, 'YYYY-MM-DD'))) {
                    newErrorFields['startDate'] = t('Invalid Date')
                    isValid = false
                }
                const blackoutDate = (jsonToArrConverter(holidays.blackout_dates))?.filter((val: string) => val.indexOf(moment(startDate).format('YYYY-MM-DD')) >= 0);
                if (blackoutDate.length > 0) {
                    newErrorFields['startDate'] = t('Invalid Date')
                    isValid = false
                }
            }
        }
        
        setErrorFields(newErrorFields)
        return isValid
    }

    function replaceEncodedSpaces(str:any) {
        if(str?.length>0){
            return str?.split('%20')?.join(' ');
        }
    }

    const handleSave = async () => {
        if (loading || !validate()) return;
        setLoading(true);

        let serviceTypeVal = 'MoveIn',
        requestedDate = moment().add(1, 'days').format('MM/DD/YYYY')
        if (serviceType && parseInt(serviceType.value) > 1) {
            serviceTypeVal = switchingType
        }
        if (serviceType && parseInt(serviceType.value) < 3) {
            requestedDate = moment(startDate).format('MM/DD/YYYY')
        }
        if (serviceType && parseInt(serviceType.value) === -1) {
            serviceTypeVal = 'Switching'
            requestedDate = 'false'
        }

        const url = new URL(window.location.href);
        const agentId = url.searchParams.get('agent_id');
        const hearus = url.searchParams.get('hearus');

        let saveFields = {
            service_address: serviceAddress.street,
            service_zipcode: serviceAddress.zipcode,
            service_city: serviceAddress.city,
            service_state: serviceAddress.state,
            service_type: serviceTypeVal,
            requested_date: requestedDate,
            product: selectedPlans[0] ? selectedPlans[0].product : '',
            esiid: serviceAddress.esiid,
            agent_id: agentId,
            hear_us: replaceEncodedSpaces(hearus)
        }
        
        saveEnrollment((enrollmentTokens[0]?.enrollment_id || null), saveFields, (enrollmentTokens[0]?.sign || null)).then(async(res) => {
            setLoading(false)

            if (res && parseInt(res.status) === 1 && res.response) {
                const { enrollment_id, sign, product } = res.response;
                if (enrollment_id) {
                    await saveCookieData(enrollment_id,sign,getCookieData('cookiesAccepted')=="true"?"accepted":"rejected",getCookieData("cookiesAcceptedDate"))
                    handleContinue([{
                        productId: product,
                        enrollment_id,
                        sign
                    }]);

                } else {
                    handleSetErrorModalContent({
                        title: t('Enrollment Id does not exist')
                    });
                }
            } else {
                handleSetErrorModalContent({
                    title: t(res.message)
                });
            }
        })
    }

    const searchTxUtility = (address: any) => {
        if (loadingUtility) return;

        setLoadingUtility(true);
        const { duns, zipcode } = address;
        const findDuns: any = dunsUtilityList.find(val => val.value === duns);
        
        if (findDuns) {
            if (selectedUtlity.Electric !== findDuns.code) {
                changeUtilityList([
                    {
                        commodity_name: 'Electric',
                        utility_code: findDuns.code,
                        utility_id: findDuns.id,
                        utility_name: findDuns.label,
                        name: findDuns.label,
                        zipcode: zipcode
                    }
                ]);
            }
            setLoadingUtility(false);
        }
        else {
            const { zipcode } = address;
            fetchTxUtility(zipcode).then(res => {
                setLoadingUtility(false);
                if (res) {
                    if (res.errorCode === '500') {
                        handleSetErrorModalContent({
                            title: t("We're sorry, CleanSky Energy currently does not service this area."),
                            desc: ' '
                        });
                        changeUtilityList(undefined);
                    }
                    else {
                        const { utility } = res.response;
                        changeUtilityList(utility);
                    }
                }
            })
        }
    }

    const showInput = () => {
        return (
            <>
                {
                    (planIsLoaded && selectedPlans.length === 0 && !isShowPlanModal) ?
                    <div className="mt-3">
                        <Button sm secondary onClick={() => handleOpenPlanModal()}>Choose Plan</Button>
                    </div> :
                    <>
                        <h6 className="mt-2 mb-2">{t('Service Address')} *</h6>
                        <ValidateAddress
                            handleSearchUtility={(address: any) => searchTxUtility(address)}
                            loadingUtility={loadingUtility}
                            manualAddressFields={manualAddressFields}
                            setManualAddressFields={setManualAddressFields}
                        />
                    </>
                }
            </>
        )
    }

 

    if (!show) return null
    return (
        <EnrollmentStepWrapper>
            { showInput() }
            {
                (serviceAddress && selectedPlans[0]) &&
                <>
                    {isAgent && serviceAddress && selectedPlans[0] && <SwitchHoldStatus address={serviceAddress} esiid={selectedPlans[0]?.esiid} setSwitchStatus={setSwitchStatus} setEnrollmentTokens={setEnrollmentTokens}/>}
                    {(!isAgent || switchStatus === false) && <>
                        <EnrollmentFormRow>
                            <EnrollmentFormCol full>
                                <Form.Group className={errorFields['serviceType'] ? 'error' : ''}>
                                    <RadioBox
                                        title={`${t('Why do you need this service?')} *`}
                                        options={serviceTypeOptions}
                                        select={serviceType}
                                        handleSelect={(val: any) => {
                                            setServiceType(val)
                                            if(val && val.value === '2'){
                                                setShowDatePicker(false)
                                                setSwitchingType("StandardSwitch")
                                                const availableDate = getAutoStartDate()
                                                setStartDate(moment(availableDate))
                                            }
                                        }}
                                    />
                                    <FormError message={errorFields['serviceType']} />
                                </Form.Group>
                            </EnrollmentFormCol>
                        </EnrollmentFormRow>
                        {
                            (serviceType && serviceType.value === '2') &&
                            <>
                                <EnrollmentFormRow>
                                    <EnrollmentFormCol full>
                                        { (!selectedPlans[0]?.available_from_date) && <CheckboxItem
                                            label={
                                                t('Standard Switch: The utility will choose the date (typically within 48 hours)')
                                            }
                                            value={"StandardSwitch"}
                                            checked={switchingType === "StandardSwitch"}
                                            handleClick={() => {
                                                setShowDatePicker(false)
                                                setSwitchingType("StandardSwitch")
                                                const availableDate = getAutoStartDate()
                                                setStartDate(moment(availableDate))
                                            }}
                                            onlyread={false}
                                            disabled={loading}
                                            id='is-agree-tos'
                                            error={errorFields['isAgree']}
                                        />}
                                        <CheckboxItem
                                            label={
                                                t("Self-Selected Switch: I'd like to pick my switch date")
                                            }
                                            value={"SelfSelectedSwitch"}
                                            checked={(selectedPlans[0]?.available_from_date) || switchingType === "SelfSelectedSwitch"}
                                            handleClick={() => {
                                                setStartDate(undefined)
                                                setSwitchingType("SelfSelectedSwitch")
                                                setShowDatePicker(true)
                                                setErrorFields({})
                                            }}
                                            onlyread={false}
                                            disabled={loading}
                                            id='is-agree-tos'
                                            error={errorFields['isAgree']}
                                        />
                                    </EnrollmentFormCol>
                                </EnrollmentFormRow>
                            </>
                        }
                        {
                            ((serviceType && serviceType.value === '1') || showDatepicker || (selectedPlans[0]?.available_from_date && serviceType)) &&
                            <>
                                <EnrollmentFormRow>
                                    <EnrollmentFormCol>
                                        <Form.Group className={errorFields['startDate'] ? 'error' : ''}>
                                            <Form.Label>{t('Select Date')} *</Form.Label>
                                            <Datetime
                                                locale={i18n.language === 'es' ? 'es' : 'en'}
                                                value={startDate}
                                                onChange={(date: any) => setStartDate(date)}
                                                initialViewDate={selectedPlans[0]?.available_from_date ? (moment(selectedPlans[0]?.available_from_date).format('MM/DD/YYYY')):startDate}
                                                dateFormat="MM/DD/YYYY"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                inputProps={{
                                                    placeholder: t('MM/DD/YYYY'),
                                                    id: 'select-start-date',
                                                    readOnly: true
                                                }}
                                                isValidDate={(currentDate) => {
                                                    if (holidays) {
                                                        if (currentDate.isBefore(moment().format('YYYY-MM-DD')) || currentDate.isAfter(moment(holidays.end_date, 'YYYY-MM-DD'))) return false;
                                                        const blackoutDate = (jsonToArrConverter(holidays.blackout_dates))?.filter((val: string) => val.indexOf(moment(currentDate).format('YYYY-MM-DD')) >= 0);
                                                        if (blackoutDate.length > 0) return false;
                                                        return true;
                                                    }
                                                    else if (currentDate.isBefore(moment().format('YYYY-MM-DD'))){
                                                        return false;
                                                    }
                                                    return true;
                                                }}
                                            />
                                        </Form.Group>
                                        <FormError message={errorFields['startDate']} />
                                    </EnrollmentFormCol>
                                </EnrollmentFormRow>
                                <p style={{marginTop:-20,fontSize:14,color:'#666'}}>
                                    {t('It may take 24 - 48 hours from your service request date for services to be connected.')}
                                </p>
                                <p style={{fontSize:14,color:'#666'}}>
                                    {t('Please note that CleanSky Energy will submit your enrollment request for the date that you select, but actual connectivity of service is determined by your local utility provider. Service connectivity may not be available on weekends or holidays in some areas. In addition, there may be utility charges based on time of day, meter type, and/or additional work needed, for example if a new meter installation is required. Weekends and holidays may be excluded or may have additional charges based on meter type. If a permit is required, it may affect your service date.')}
                                </p>
                            </>
                        }
                        <EnrollmentStepFooter>
                            <div />
                            <Button type="button" disabled={loading} onClick={() => handleSave()} data-test-id="cta">
                                <span>{t('Continue')}</span>
                                {loading && (
                                    <Spinner animation="border" variant="light" size="sm" className="ms-2" />
                                )}
                            </Button>
                        </EnrollmentStepFooter>
                    </>}
                </>
            }
        </EnrollmentStepWrapper>
    )
}
